import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Card from 'react-bootstrap/Card'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import Sal from 'sal.js'
import '../style/style.css'

import { useIntl, injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const OurProduct = () => {
    Sal()
    const intl = useIntl()

    const data = useStaticQuery(
        graphql`
        query {
            allContentfulOurProductHomepage(sort: {fields: order}) {
                nodes {
                    id
                    title
                    node_locale
                    childContentfulOurProductHomepageDescriptionRichTextNode {
                        json
                    }
                    image {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }`)


    return (

        <div className="homePageProductsContainer">
            <h2><FormattedMessage id="homePageProds_title" /></h2>
            <hr className="bookends" />
            <p><FormattedMessage id="homePageProds_discrp" /></p>
            <article className="homePageProducts">
                {
                    data.allContentfulOurProductHomepage.nodes.filter(lang => lang.node_locale === intl.locale).map(prod => {
                        // let img = "https://" + prod.image.fluid.src
                        return (
                            <Card className="prod" key={prod.id}>
                                {/* <Card.Img variant="top" src={img} onContextMenu={(e) => e.preventDefault()} /> */}
                                <Img
                                    onContextMenu={(e) => e.preventDefault()}
                                    key={prod.id}
                                    fluid={prod.image.fluid}
                                    title={prod.title}
                                    alt={prod.title}
                                />
                                <Card.Body>
                                    <Card.Title>{prod.title}</Card.Title>
                                    <Card.Text as="span">
                                        {documentToReactComponents(prod.childContentfulOurProductHomepageDescriptionRichTextNode.json)}
                                        <span className="prodReadMore"><Link to="/our-products"><FormattedMessage id="read_more" /></Link></span>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )
                    })

                }
            </article >
        </div >
    )
}


export default injectIntl(OurProduct)

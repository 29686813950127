import React from "react"

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'


import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import HomepageAbout from "../components/homepageAbout"
import OurProduct from "../components/ourProduct"
import ContactForm from "../components/contactForm"
import Qualities from "../components/ourQualities"

import { useIntl } from "gatsby-plugin-intl"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "homePage_seo_title" })}
        description={intl.formatMessage({ id: "homePage_seo_description" })} />
      <Carousel />
      <HomepageAbout />
      <OurProduct />
      <Qualities />
      <ContactForm />
      <WhatsAppWidget phoneNumber='+3223155231' companyName="Batrade Support" message={intl.formatMessage({ id: "whatapp_response" })} textReplyTime={intl.formatMessage({ id: "whatapp_response_delay" })} sendButton={intl.formatMessage({ id: "whatapp_sendButton" })} />
    </Layout>
  )

}

export default IndexPage

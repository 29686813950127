import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

import '../style/style.css'

import { useIntl, injectIntl } from "gatsby-plugin-intl"

const HomePageAbout = () => {
    const intl = useIntl()

    const data = useStaticQuery(
        graphql`
        query {
            allContentfulHomePageAboutText {
                nodes {
                    id
                    order
                    title
                    node_locale
                    childContentfulHomePageAboutTextDescriptionRichTextNode {
                        json
                        }
                }
            }
            allContentfulHomePageAboutMedia {
                nodes {
                    id
                    media {
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    title
                    node_locale
                    }
                    mediaTitle
                    seoDescription
                }
            }
        }`)

    return (
        <article className="aboutHomepage">
            <section className="aboutHomePageText" >
                {
                    data.allContentfulHomePageAboutText.nodes.filter(lang => lang.node_locale === intl.locale).map(artl => {
                        if (artl.order === 1) {
                            return (
                                <article key={artl.id} className="aboutHomePageFirstText">
                                    <section>
                                        <h1>{artl.title.replace('Batrade', '')} <span className="batrade">Batrade</span></h1>
                                        <section>
                                            {documentToReactComponents(artl.childContentfulHomePageAboutTextDescriptionRichTextNode.json)}
                                        </section>
                                    </section>
                                </article >
                            )
                        }
                        else return ''
                    })

                }
                {
                    data.allContentfulHomePageAboutText.nodes.filter(lang => lang.node_locale === intl.locale).reverse().map(artl => {
                        if (artl.order !== 1 && data.allContentfulHomePageAboutText.nodes.filter(lang => lang.node_locale === intl.locale).length > 1) {
                            return (
                                <article key={artl.id} className="aboutHomePageOthersText">
                                    <section>
                                        <h2>{artl.title}</h2>
                                        <section>
                                            {documentToReactComponents(artl.childContentfulHomePageAboutTextDescriptionRichTextNode.json)}
                                        </section>
                                    </section>
                                </article >
                            )
                        }
                        else return ''

                    })
                }
            </section>
            <section className="aboutHomePageMedia" onContextMenu={(e) => e.preventDefault()} role='presentation'>
                {
                    data.allContentfulHomePageAboutMedia.nodes.filter(artl => artl.media.node_locale === intl.locale).map(artl => {
                        return (
                            <Img fluid={artl.media.fluid} alt={artl.media.seoDescription} title={artl.media.mediaTitle} key={artl.id} />
                        )
                    })
                }
            </section>
        </article>
    )
}


export default injectIntl(HomePageAbout)

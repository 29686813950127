import React from "react"
import { useStaticQuery, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'

import '../style/style.css'

import { useIntl, injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

const OurQualities = () => {
    const intl = useIntl()

    const data = useStaticQuery(
        graphql`
        query {
            allContentfulQulity {
                nodes {
                    slug
                    title
                    shortDiscription
                    node_locale
                }
            }
        }`)
    return (
        <div className="OurQualitiesContainer">
            {
                data.allContentfulQulity.nodes.filter(lang => lang.node_locale === intl.locale).map(q => {

                    if (q.slug === "env") {
                        return (
                            <article className="quality" key={q.title}>
                                <section>
                                    <FontAwesomeIcon icon={faLeaf} />
                                </section>
                                <h3>{q.title}</h3>
                                <p>{q.shortDiscription}</p>
                                <Link to="/about-us" ><button aria-label="read more"><FormattedMessage id="read_more" /></button></Link>
                            </article>
                        )
                    }
                    if (q.slug === "quality")
                        return (
                            <article className="quality qmidl" key={q.title}>
                                <section>
                                    <FontAwesomeIcon icon={faAward} />
                                </section>
                                <h3>{q.title}</h3>
                                <p>{q.shortDiscription}</p>
                                <Link to="/about-us"><button aria-label="read more"><FormattedMessage id="read_more" /></button></Link>
                            </article>
                        )
                    if (q.slug === "contact")
                        return (
                            <article className="quality" key={q.title}>
                                <section>
                                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                                </section>
                                <h3>{q.title}</h3>
                                <p>{q.shortDiscription}</p>
                                <Link to="/contact-us"><button aria-label="read more"><FormattedMessage id="read_more" /></button></Link>
                            </article>
                        )
                    else
                        return null
                })
            }
        </ div >

    )
}

export default injectIntl(OurQualities)
